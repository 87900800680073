import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import './OurPropertiesVirtual.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import OutdoorMap, {
  MarkerLocationsTypes,
} from '../../components/OutdoorMap/OutdoorMap'
import getDeviceLocation from '../../services/getDeviceLocation'
import getDistance from '../../services/getDistance'
import Seo from '../../components/Seo/Seo'
import placeholder from '../../assets/images/location-images/location-placeholder.png'
import PROPERTIES_META_DATA from '../../data/properties-meta-data.json'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const PropertySearch = loadable(() =>
  import('../../components/PropertySearch/PropertySearch'),
)

const DISTANCE_RADIUS = 100_000

type TowerTypes = {
  name: string
  locationLandmarks?: {
    locationBrief: string
    locationDefault: string
    locationSpecific: string
    latitude: string
    longitude: string
    region: string
    locationImages?: {
      url: string
      featured: string
    }[]
    landmarksEstablishment?: {
      items: {
        establishmentName: string
        establishmentBody: string
        establishmentDistance: string
        establishmentLocation: string
        establishmentLatitude: string
        establishmentLongitude: string
        imagesGallery: {
          description: string
          url: string
          featured: boolean
        }[]
      }[]
    }
  }
  constructionUpdates?: {
    constructionEndDate: string
    details: string
    constructions?: {
      constructionUpdate: string
      details: string
    }[]
    imageGallery: {
      url: string
    }[]
  }
  amenities?: {
    title?: string
    propertyDetails?: string
    items: {
      amenityName: string
      images: {
        url: string
      }[]
    }[]
  }
  overviewDetails?: {
    propertyStatus: string
    propertyDetails: string
    propertyTitle: string
    imagesGallery: [
      {
        url: string
        featured: boolean
      },
    ]
    panoramas?: {
      url: string
      featured: string
    }[]
  }
}

type PropertyTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  image: {
    url: string
    description: string
    featured: boolean
  }
  projectName: string
  propertyPrice: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType?: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  location?: string
  ltsAndApprovalNo?: string
  towers: TowerTypes[]
}

type PageContextType = {
  type: string
  location?: string
}

type MainPropTypes = {
  data: {
    allProperty: { nodes: PropertyTypes[] }
  }
  pageContext: PageContextType
  location: {
    state: {
      searchInput?: any
      location?: any
      type?: any
      price?: string
    }
    pathname: string
  }
}
const getPropertiesWithGeoLocation = (
  propertyList: PropertyTypes[],
): MarkerLocationsTypes[] => {
  const propertPositionList = propertyList.map((property) => {
    if (
      property.towers[0]?.locationLandmarks?.latitude &&
      property.towers[0]?.locationLandmarks?.longitude
    ) {
      return {
        projectName: property.projectName,
        image: property.towers[0]?.overviewDetails?.imagesGallery?.find(
          (unit) => unit.featured,
        )?.url,
        position: {
          lat: parseFloat(property.towers[0]?.locationLandmarks?.latitude),
          lng: parseFloat(property.towers[0]?.locationLandmarks?.longitude),
        },
        propertyType: `${property.propertyType || ''}`,
      }
    }
    return null
  })

  return propertPositionList.filter(
    (property) => property,
  ) as MarkerLocationsTypes[]
}

const OurPropertiesVirtual = ({
  data: {
    allProperty: { nodes: propertyList },
  },
  pageContext,
  location,
}: MainPropTypes): ReactElement => {
  const [allPropertyList] = useState(
    propertyList.sort((a, b) => a?.name.localeCompare(b?.name)),
  )
  const [allProperties] = useState(
    [...allPropertyList].filter(
      (property) => property.propertyType !== 'Estate',
    ),
  )
  const [priceRange, setPriceRange] = useState([0, 0])
  const [noData, setNoData] = useState(false)
  const [establishments] = useState(
    propertyList.filter((val) =>
      val.towers[0]?.locationLandmarks?.landmarksEstablishment?.items?.map(
        (landmark) => landmark?.establishmentName,
      ),
    ),
  )
  const [filteredPropertyList, setFilteredPropertyList] = useState(
    allPropertyList.filter(
      (property) =>
        (property.name
          .toLowerCase()
          .includes(location?.state?.searchInput?.toLowerCase()) ||
          property.towers[0]?.locationLandmarks?.locationBrief
            ?.toLowerCase()
            .includes(location?.state?.searchInput?.toLowerCase()) ||
          property.propertyType
            ?.toLowerCase()
            .includes(location?.state?.searchInput?.toLowerCase())) &&
        property.towers[0]?.locationLandmarks?.locationBrief
          ?.toLowerCase()
          .includes(location?.state?.location?.toLowerCase()) &&
        property.propertyType
          ?.toLowerCase()
          .includes(location?.state?.type?.toLowerCase()) &&
        parseInt(property.propertyPriceRangeLowest?.replace(/,/g, ''), 10) >=
          priceRange[1] &&
        parseInt(property.propertyPriceRangeHighest?.replace(/,/g, ''), 10) <=
          priceRange[0],
    ),
  )

  const [mapCenter, setMapCenter] = useState<{
    lat: number
    lng: number
  }>({
    lat: 14.5806921,
    lng: 121.0647492,
  })
  const [deviceLocation, setDeviceLocation] = useState<
    | {
        lat: number
        lng: number
      }
    | undefined
  >(undefined)
  const [propertiesWithinRadius, setPropertiesWithinRadius] = useState<
    MarkerLocationsTypes[]
  >([])
  const [searchTerm, setSearchTerm] = useState(false)
  const [page, setPage] = useState('')

  const parseValue = (price: string): number => {
    if (price === 'ABOVE') {
      return 1e20
    }

    return parseFloat(price.replace('M', '')) * 1e6
  }

  const parseRange = (range: string): number[] | null => {
    if (!range) {
      return null
    }

    const [lowestValue, highestValue] = range.split(' - ')

    if (range === 'Less than 1m') {
      return [0, 999999]
    }

    if (range === 'ALL') {
      return null
    }

    return [parseValue(lowestValue), parseValue(highestValue)]
  }

  useEffect(() => {
    setPage('/our-properties-virtual')
  }, [page])

  useEffect(() => {
    if (location?.state?.price === 'Less than 1m') {
      setPriceRange([999999, 0])
    } else if (location?.state?.price === '1M - 1.9M') {
      setPriceRange([1999999, 1000000])
    } else if (location?.state?.price === '2M - 2.9M') {
      setPriceRange([2999999, 2000000])
    } else if (location?.state?.price === '3M AND UP') {
      setPriceRange([700000000, 3000000])
    } else if (location?.state?.price === 'ALL') {
      setPriceRange([70000000, 0])
    } else {
      setPriceRange([70000000, 0])
    }
  }, [location])

  useEffect(() => {
    setFilteredPropertyList(
      !location?.state?.location &&
        !location?.state?.searchInput &&
        !location?.state?.price &&
        !location?.state?.type
        ? allProperties
        : allPropertyList.filter((property) => {
            const filteredSearchTerm =
              location?.state?.searchInput?.toLowerCase().trim() || ''
            const filteredSearchType =
              location?.state.type?.toLowerCase().trim() || ''
            const filteredSearchLocation =
              location?.state?.location?.toLowerCase().trim() || ''
            const filteredPriceRange = parseRange(location?.state?.price || '')

            if (
              filteredSearchTerm !== '' &&
              !property.name?.toLowerCase().includes(filteredSearchTerm) &&
              !property.propertyType
                ?.toLowerCase()
                .includes(filteredSearchTerm) &&
              !property.towers[0]?.locationLandmarks?.locationBrief
                ?.toLowerCase()
                .includes(filteredSearchTerm)
            ) {
              return false
            }

            if (
              filteredSearchType !== '' &&
              !property.propertyType?.toLowerCase().includes(filteredSearchType)
            ) {
              return false
            }

            if (
              filteredSearchLocation !== '' &&
              !property.towers[0]?.locationLandmarks?.locationBrief
                ?.toLowerCase()
                .includes(filteredSearchLocation)
            ) {
              return false
            }

            if (filteredPriceRange) {
              const priceLowest = parseInt(
                property.propertyPriceRangeLowest?.replace(/,/g, ''),
                10,
              )
              const priceHighest = parseInt(
                property.propertyPriceRangeHighest?.replace(/,/g, ''),
                10,
              )

              const [filterLowestPrice, filterHighestPrice] = filteredPriceRange

              let shouldIncludeData = false

              if (
                (filterLowestPrice >= priceLowest &&
                  filterLowestPrice <= priceHighest) ||
                (filterHighestPrice >= priceLowest &&
                  filterHighestPrice <= priceHighest)
              ) {
                shouldIncludeData = true
              }

              return shouldIncludeData
            }

            return true
          }),
    )

    if (
      location.pathname === '/condominium' ||
      location.pathname === '/condominium/'
    ) {
      setFilteredPropertyList(
        allPropertyList.filter((property) =>
          property.propertyType?.toLowerCase().includes('condominium'),
        ),
      )
    } else if (
      location.pathname === '/house-lot' ||
      location.pathname === '/house-lot/'
    ) {
      setFilteredPropertyList(
        allPropertyList.filter((property) =>
          property.propertyType?.toLowerCase().includes('house and lot'),
        ),
      )
    } else if (
      location.pathname === '/lots' ||
      location.pathname === '/lots/'
    ) {
      setFilteredPropertyList(
        allPropertyList.filter((property) =>
          property.propertyType?.toLowerCase().includes('estate'),
        ),
      )
    } else if (
      location.pathname.slice(0, 10) === '/locations' ||
      location.pathname.slice(0, 10) === '/locations/'
    ) {
      setFilteredPropertyList(
        allPropertyList.filter((property) =>
          property.location
            ?.toLowerCase()
            .includes(pageContext?.location || ''),
        ),
      )
    } else if (location.pathname.slice(0, 12) === '/condominium') {
      setFilteredPropertyList(
        allPropertyList.filter(
          (property) =>
            property.location
              ?.toLowerCase()
              .includes(pageContext?.location || '') &&
            property.propertyType?.toLowerCase().includes('condominium'),
        ),
      )
    } else if (location.pathname.slice(0, 10) === '/house-lot') {
      setFilteredPropertyList(
        allPropertyList.filter(
          (property) =>
            property.location
              ?.toLowerCase()
              .includes(pageContext?.location || '') &&
            property.propertyType?.toLowerCase().includes('house and lot'),
        ),
      )
    } else if (location.pathname.slice(0, 8) === '/lots') {
      setFilteredPropertyList(
        allPropertyList.filter(
          (property) =>
            property.location
              ?.toLowerCase()
              .includes(pageContext?.location || '') &&
            property.propertyType?.toLowerCase().includes('estate'),
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange, allPropertyList, location, searchTerm])

  useEffect(() => {
    setNoData(false)
    if (
      (location?.state?.searchInput?.length > 0 ||
        location?.state?.location?.length > 0 ||
        location?.state?.type?.length > 0 ||
        location?.state?.price) &&
      filteredPropertyList.length === 0
    ) {
      setNoData(true)
    } else if (
      location?.state?.searchInput?.length > 0 &&
      filteredPropertyList.length > 0
    ) {
      setNoData(false)
    }
  }, [filteredPropertyList, location])

  useEffect(() => {
    setNoData(false)
    if (searchTerm && filteredPropertyList.length === 0) {
      setNoData(true)
    } else if (searchTerm && filteredPropertyList.length > 0) {
      setNoData(false)
    }
  }, [searchTerm, location, filteredPropertyList.length])

  useEffect(() => {
    setPropertiesWithinRadius(
      getPropertiesWithGeoLocation(propertyList).filter(
        (property) =>
          getDistance(mapCenter, property.position) <= DISTANCE_RADIUS,
      ),
    )
  }, [mapCenter, propertyList])

  useEffect(() => {
    if (!deviceLocation) {
      getDeviceLocation()
        .then((loc) => {
          if ('latitude' in loc.coords && 'longitude' in loc.coords) {
            const coordinates = {
              lat: loc.coords.latitude,
              lng: loc.coords.longitude,
            }
            setDeviceLocation(coordinates)
            setMapCenter(coordinates)
          }
        })
        .catch(() => null)
    }
  }, [deviceLocation])

  const handleLocationChange = useCallback((loc) => {
    setMapCenter(loc.geoLocation)
  }, [])

  const brokenImage = (event: any): void => {
    event.target.src = placeholder // eslint-disable-line no-param-reassign
  }

  const handlePropertyType = useCallback((propertyType) => {
    if (propertyType === 'House and Lot') {
      return 'house-lot'
    }
    if (propertyType === 'Condominium') {
      return 'condominium'
    }
    return 'lots'
  }, [])

  const getLocationData = (index: number): any => {
    return PROPERTIES_META_DATA[index].data?.find((item) =>
      pageContext?.location!.includes(item.location),
    )
  }

  const capitalizeFirstChar = (text: any): string => {
    const textArr = text.split('-')
    const result = textArr.reduce((acc: string, word: string) => {
      // eslint-disable-next-line prefer-template, no-param-reassign
      acc = acc + ' ' + (word.charAt(0).toUpperCase() + word.slice(1))
      return acc
    }, '')
    return result
  }

  const getMetaTitle = (): string => {
    switch (pageContext?.type) {
      case 'locations':
        return `${
          getLocationData(1)?.locationLabel
        } | Amaia Land | Affordable House and Lot & Condos in the Philippines`
      case 'condominium':
        return !pageContext?.location
          ? PROPERTIES_META_DATA[1]?.defaultTitle
          : `Affordable Condominium in ${capitalizeFirstChar(
              pageContext?.location,
            )} | Amaia Land`
      case 'house-lot':
        return !pageContext?.location
          ? PROPERTIES_META_DATA[2]?.defaultTitle
          : `Affordable House and Lot in ${capitalizeFirstChar(
              pageContext?.location,
            )} | Amaia Land`
      case 'lots':
        return !pageContext?.location
          ? PROPERTIES_META_DATA[3]?.defaultTitle
          : `Affordable Residential and Commercial Properties in ${capitalizeFirstChar(
              pageContext?.location,
            )} | Amaia Land`
      default:
        return 'Our Properties | Amaia Land Affordable Properties'
    }
  }

  const getMetaDescription = (): string => {
    switch (pageContext?.type) {
      case 'locations':
        return getLocationData(0)?.description
      case 'condominium':
        return pageContext?.location
          ? getLocationData(1)?.description
          : PROPERTIES_META_DATA[1]?.defaultDescription
      case 'house-lot':
        return pageContext?.location
          ? getLocationData(2)?.description
          : PROPERTIES_META_DATA[2]?.defaultDescription
      case 'lots':
        return pageContext?.location
          ? getLocationData(3)?.description
          : PROPERTIES_META_DATA[3]?.defaultDescription
      default:
        return 'Amaia Land Affordable Properties'
    }
  }

  return (
    <Layout>
      <Seo
        title={getMetaTitle()}
        jsonData={{
          keywords:
            'properties, condo, philippines, house and lot, amaia, apartment',
        }}
        ogMetaData={{
          description: getMetaDescription(),
        }}
      />

      <div className="properties">
        <div className="location-finder">
          <PropertySearch
            searched={setSearchTerm}
            page={page}
            onLocationChange={handleLocationChange}
          />
        </div>
        <h1 className="page-title">{getMetaTitle()?.split('|')[0]}</h1>
        <div className="properties-content">
          <div className="map-container">
            <OutdoorMap
              id="map"
              center={mapCenter}
              zoom={10}
              radius={DISTANCE_RADIUS}
              markerLocations={propertiesWithinRadius}
            />
            <div className="neighborhoods">
              <h3>Explore our Neighborhoods</h3>
              <p style={{ fontSize: '14px' }}>
                To explore Amaia neighborhoods, click the button below.
              </p>
              {establishments.length > 3 ? (
                <Link to="/locations">
                  <button type="button">Explore More</button>
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
          {noData || filteredPropertyList.length === 0 ? (
            <div className="no-result">
              Search result for{' '}
              {location?.state?.searchInput
                ? `"${location?.state?.searchInput}"`
                : ''}{' '}
              {location?.state?.location || pageContext?.location
                ? `"${
                    location?.state?.location ||
                    capitalizeFirstChar(pageContext?.location)
                  }"`
                : ''}{' '}
              {location?.state?.type || pageContext?.type
                ? `"${
                    location?.state?.type ||
                    capitalizeFirstChar(pageContext?.type)
                  }"`
                : ''}{' '}
              {location?.state?.price ? `"${location?.state?.price}"` : ''} (0)
            </div>
          ) : (
            <div className="property-container">
              {filteredPropertyList
                .filter(
                  (list) => list?.towers[0]?.overviewDetails?.panoramas[0]?.url,
                )
                .map((prop) => {
                  return (
                    prop.towers[0]?.overviewDetails?.imagesGallery && (
                      <div key={`property${prop?.id}`} className="property">
                        <Link
                          to={`/${handlePropertyType(prop.propertyType)}/${
                            prop.location
                          }/${prop.slug}/virtual-tour`}
                          className="link"
                        >
                          <div className="property-image x">
                            <LazyLoadImage
                              src={
                                prop.towers[0]?.overviewDetails?.imagesGallery?.find(
                                  (unit) => unit.featured,
                                )?.url
                              }
                              onError={brokenImage}
                              alt="propertyLogo"
                            />
                            {prop.towers[0]?.overviewDetails
                              ?.propertyStatus && (
                              <div className="status-container">
                                <span>
                                  {
                                    prop.towers[0]?.overviewDetails
                                      ?.propertyStatus
                                  }
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="property-details">
                            <h4 className="type">{prop.propertyType}</h4>
                            <h4 className="name">{prop.projectName}</h4>
                            <h4 className="location">
                              {prop.towers[0]?.locationLandmarks?.locationBrief}
                            </h4>
                          </div>
                          <div className="launch-tour">LAUNCH VIRTUAL TOUR</div>
                        </Link>
                      </div>
                    )
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default OurPropertiesVirtual

export const pageQuery = graphql`
  query {
    allProperty {
      nodes {
        ...PropertyPageFields
      }
    }
  }
`
